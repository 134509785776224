import React, { useEffect, useState } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';

import css from './SearchFiltersPrimary.module.css';
import { Button, RangeSlider } from '../../../components';
import { useHistory } from 'react-router-dom';
import { defaultLocation } from '../../../config/configListing';
const SearchFiltersPrimaryForGridComponent = props => {
  const {
    rootClassName,
    className,
    children,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    radiusSlider, 
    updateRadiusSlider
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const [max, setMax] = useState(200);
  
  const [kilometersToAdd, setKilometersToAdd] = useState(40);
  const [isSearchMap, setIsSearchMap] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const kiloParam = new URLSearchParams(window.location.search).get('kilo');
    updateRadiusSlider(Number(kiloParam) || defaultLocation.RADIUS_KM);
  }, []);
  // Function to add kilometers to latitude and longitude
  function addKilometersToCoordinates(latitude, longitude, direction, kilometersToAdd) {
    const latitudeDegreePerKilometer = 1 / 111; // Approximation, valid for most practical purposes
  
    // Adjust longitude degree per kilometer based on current latitude
    const longitudeDegreePerKilometer = 1 / (111 * Math.cos(latitude * (Math.PI / 180)));
  
    // Convert direction to angles
    let latChange = 0;
    let longChange = 0;
  
    switch (direction.toUpperCase()) {
      case "N":
        latChange = kilometersToAdd * latitudeDegreePerKilometer;
        break;
      case "S":
        latChange = -kilometersToAdd * latitudeDegreePerKilometer;
        break;
      case "E":
        longChange = kilometersToAdd * longitudeDegreePerKilometer;
        break;
      case "W":
        longChange = -kilometersToAdd * longitudeDegreePerKilometer;
        break;
      case "NE":
        latChange = kilometersToAdd * latitudeDegreePerKilometer;
        longChange = kilometersToAdd * longitudeDegreePerKilometer;
        break;
      case "NW":
        latChange = kilometersToAdd * latitudeDegreePerKilometer;
        longChange = -kilometersToAdd * longitudeDegreePerKilometer;
        break;
      case "SE":
        latChange = -kilometersToAdd * latitudeDegreePerKilometer;
        longChange = kilometersToAdd * longitudeDegreePerKilometer;
        break;
      case "SW":
        latChange = -kilometersToAdd * latitudeDegreePerKilometer;
        longChange = -kilometersToAdd * longitudeDegreePerKilometer;
        break;
      default:
        throw new Error("Invalid direction");
    }
  
    // Add the changes to the coordinates
    const newLatitude = latitude + latChange;
    const newLongitude = longitude + longChange;
  
    return [newLatitude, newLongitude];
  }
  

  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <PopupOpenerButton
      isSelected={isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0}
      toggleOpen={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </PopupOpenerButton>
  ) : null;

  const updateSlider = (e) => {
    if (radiusSlider != e[1]) {
      let distance = e[1] > radiusSlider ? e[1] : -e[1]
      updateRadiusSlider(e[1]);
      setKilometersToAdd(distance);
      setIsSearchMap(true);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('kilo', distance);
      history.push(window.location.pathname + "?" + urlParams.toString());
    }
  }

  const searchRadiusFilter = () => {
    if (isSearchMap == true) {
      const urlParams = new URLSearchParams(window.location.search);
      const bounds = urlParams.get('bounds') ??  defaultLocation.FILTER_BOUND;
      const [neLatitude, neLongitude, swLatitude, swLongitude] = bounds.split(',');

      const [newNeLatitude, newNeLongitude] = addKilometersToCoordinates(Number(neLatitude), Number(neLongitude), 'NE', Number(kilometersToAdd));
      const [newSwLatitude, newSwLongitude] = addKilometersToCoordinates(Number(swLatitude), Number(swLongitude), 'SW', Number(kilometersToAdd));

      if(newNeLatitude && newNeLongitude && newSwLatitude && newSwLongitude){
        const newBounds = newNeLatitude + "%2C" + newNeLongitude + "%2C" + newSwLatitude + "%2C" + newSwLongitude;
        const newUrl = window.location.pathname + window.location.search.replace(/bounds=([^&]*)/, `bounds=${newBounds}`);
    
        history.push(newUrl);
        setIsSearchMap(false);
      }
    }
  }

  return (
    <div className={classes}>
      <div className={css.sliderWrapper}>
        <FormattedMessage
          id="SearchFiltersPrimary.searchTitle"
        /> ({radiusSlider}km)
        <div>
          <RangeSlider
            max={max}
            step={1}
            handles={[0, radiusSlider]}
            onChange={handles => {
              updateSlider(handles);
            }}
          />
          <Button className={css.buttonHeight} onClick={searchRadiusFilter}>Apply</Button>
        </div>
      </div>
    </div>
  );
};

SearchFiltersPrimaryForGridComponent.defaultProps = {
  rootClassName: null,
  className: null,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
};

SearchFiltersPrimaryForGridComponent.propTypes = {
  rootClassName: string,
  className: string,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
};

const SearchFiltersPrimaryForGrid = SearchFiltersPrimaryForGridComponent;

export default SearchFiltersPrimaryForGrid;
